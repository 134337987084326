









import { Component, Prop, Vue } from 'vue-property-decorator';
import AboutItem from '@/components/AboutItem.vue';
import Form from '@/components/Form.vue';

@Component({
    components:{
        AboutItem, 
        Form
    }
})
export default class CardContent extends Vue {
    @Prop()
    body: any;
}
