











































import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageCover from '@/components/ImageCover.vue';

@Component({
  components:{
    ImageCover
  }
})
export default class TwoShowcaseCardItem extends Vue {
  @Prop({required:true})
  data!: any;
  link1data: any = null;
  link2data: any = null;
  async mounted(){ 
    [this.link1data, this.link2data] = await Promise.all([
      this.$store.dispatch('getItem', this.data.link1.cached_url), 
      this.$store.dispatch('getItem', this.data.link2.cached_url)

    ]);
  }
}
