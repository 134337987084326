














import { Component, Prop, Vue } from 'vue-property-decorator';
import RichTextObject from '@/components/RichTextObject.vue';

@Component({
    components:{
        RichTextObject
    }
})
export default class AboutItem extends Vue {
    @Prop({required:true})
    data: any;
}
