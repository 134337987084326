








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CardItem extends Vue {
  data: any;

}
