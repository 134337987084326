

















import { bus } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import TwoShowcaseCardItem from '@/components/TwoShowcaseCardItem.vue';
import CardItem from '@/components/CardItem.vue';
import CardContent from '@/components/CardContent.vue';
import CardItemWithImage from '@/components/CardItemWithImage.vue';


@Component({ 
    components:{
        TwoShowcaseCardItem, 
        CardItem, 
        CardItemWithImage, 
        CardContent
    }
})
export default class Page extends Vue {
    data: any | null = null;
    goTo404Page(){ 
        this.$router.push('/not-found');
    }

    async mounted(){ 
        bus.$emit('showBusy');
        let slug = this.$route.params.slug;
        

        if (!slug){ 
            slug = 'home';
        }
        
        try{
            this.data = await this.$store.dispatch('getPage', slug);

            bus.$emit('setHeader',{
                title: this.data.content.title, 
                subtitle: this.data.content.subtitle,
                buttons: this.data.content.header_buttons,
                bgVideo: this.data.content.header_bg_video ? this.data.content.header_bg_video.filename : null
            })
        }catch(err){ 
            this.goTo404Page();
        }
        bus.$emit('hideBusy');
        if (!this.data){
            this.goTo404Page();
            return;
        }
    }
    
}
