


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CardItemWithImage extends Vue {
  @Prop({required:true})
  image: any;
}
