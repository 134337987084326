

























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Form extends Vue {
  @Prop({required:true})
  data: any;
}
