






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RichTextObject extends Vue {
    @Prop({required:true})
    data: any;

    htmlcontent: string | null = null;

    mounted(){ 
        this.htmlcontent = this.$store.state.storyblok.richTextResolver.render(this.data);
    }
}
